import React from 'react';

// External Components
import {
  Box,
  Paragraph,
  fullWidthMinusMargins,
  Section
} from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  VideoPlayer,
  BigArrowDown,
  MarkedHeading
} from 'components';

// Animations
import {
  progressBarPortrait,
  progressBarLandscape,
  imageSlide
} from './animations';

// Assets
import smallNoise from 'assets/png/noise/small-noise-min.png';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Statistics = ({
  staticData: { video },
  data: {
    business_year_label,
    business_year_number,
    comparison_label,
    comparison_number,
    description,
    employees_label,
    employees_number,
    production_label,
    production_number
  }
}) => {
  React.useEffect(() => {
    const killSlide = imageSlide();

    return killSlide;
  }, []);

  const statisticNumbers = [
    { number: business_year_number, label: business_year_label },
    { number: employees_number, label: employees_label },
    { number: production_number, label: production_label }
  ];

  const comparison = {
    number: comparison_number,
    label: comparison_label
  };

  return (
    // Markup
    <Section id="landing-page__statistics">
      <Numbers data={statisticNumbers} />
      <StatisticVideoPlayer data={video} />
      <Description description={description} />
      <Spacer />
      <PercentageBarPortrait data={comparison} />
      <PercentageBarLandscape data={comparison} />
    </Section>
  );
};

// Elements

const StatisticVideoPlayer = ({ data }) => {
  return (
    <VideoPlayer
      videoCode="756624591" // VIMEO EMBED ID
      data={data}
      className="statistics__video"
      sx={{
        mt: ['12.7rem', '10.9rem', '10.7rem', 0, 0, 0],
        gridRow: [null, null, null, 1, 1, 1]
      }}
    />
  );
};

const Numbers = ({ data }) => (
  <Box
    className="statistics__numbers"
    sx={{
      display: 'grid',
      gridColumn: [
        '2/span 10',
        '2/span 10',
        '6/span 14',
        '1/25',
        '3/span 20',
        '4/span 18'
      ],
      gridTemplateColumns: [
        '1fr',
        '1fr',
        '1fr',
        'repeat(3, auto)',
        'repeat(3, auto)',
        'repeat(3, auto)'
      ],
      gap: ['8rem', '10.8rem', '12rem', '8rem', '14.7rem', '14.7rem'],
      mt: [null, null, null, '3.8rem', '5rem', '5.8rem']
    }}>
    {data.map((item) => (
      <NumberBlock key={item.text} data={item} />
    ))}
  </Box>
);

const NumberBlock = ({ data: { number, label } }) => (
  <Box
    className="numbers__numbers-block"
    sx={{ textAlign: ['center', 'center', 'center', 'left', 'left', 'left'] }}>
    <Paragraph
      sx={{
        fontFamily: 'primary.italic',
        fontSize: ['5.6rem', '6.4rem', '7.2rem', '4rem', '4rem', '4.8rem'],
        lineHeight: 1
      }}>
      {number}
    </Paragraph>
    <Paragraph
      sx={{
        fontFamily: 'primary.bold',
        fontSize: ['1.5rem', '2rem', '2.2rem', '1.4rem', '1.4rem', '1.4rem'],
        lineHeight: 1.6,
        mt: ['1.2rem', '1.2rem', '1.2rem', '0.8rem', '1.6rem', '0.8rem']
      }}>
      {label}
    </Paragraph>
  </Box>
);

const Description = ({ description }) => {
  return (
    <Box
      className="statistic__description"
      sx={{
        gridColumn: [
          '1 / span 11',
          '2 / span 9',
          '3 / span 16',
          '5 / span 10',
          '7 / span 9',
          '8 / span 8'
        ],
        mt: ['14.3rem', '16.4rem', '25rem', '11.1rem', '22rem', '30rem'],
        position: 'relative'
      }}>
      <MarkedHeading
        as="p"
        data={convertApiHtmlText(description.html)}
        sx={{
          lineHeight: 1.5,
          fontSize: ['2.2rem', '2.4rem', '2.6rem', '1.8rem', '2.2rem', '2.4rem']
        }}
      />
      <BigArrowDown
        sx={{
          color: 'secondary',
          width: ['6.5rem', '8.2rem', '9.1rem', '7.2rem', '7.3rem', '8.1rem'],
          position: 'absolute',
          right: [
            '2.2rem',
            '-2rem',
            '-4.9rem',
            '-6.1rem',
            '-4.1rem',
            '-4.1rem'
          ],
          bottom: ['-8rem', '-9rem', '-9rem', '-7rem', '-4.5rem', '-5.3rem'],
          transform: [
            null,
            null,
            'rotate(342deg)',
            'rotate(288deg)',
            'rotate(288deg)'
          ]
        }}
      />
    </Box>
  );
};

const PercentageBarPortrait = ({ data }) => {
  React.useEffect(() => {
    const killAnimation = progressBarPortrait();

    return killAnimation;
  }, []);

  return (
    <Box
      className="statistics__porcentage-bar-portrait"
      sx={{
        bg: 'primary',
        backgroundImage: `url(${smallNoise})`,
        backgroundSize: 'contain',
        borderImageRepeat: 'repeat',
        display: ['block', 'block', 'block', 'none', 'none', 'none'],
        gridColumn: ['1/13', '1/13', '1/25', null, null, null],
        mt: '-0.2rem', // Avoid glitch pixel line on scroll
        mx: fullWidthMinusMargins,
        // Separated paddings because otherwise "inherit" won't work
        pt: ['7.2rem', '7.2rem', '6.3rem', null, null, null],
        px: ['inherit', 'inherit', '12.3rem', null, null, null],
        pb: ['19.6rem', '27.9rem', '38.1rem', null, null, null]
      }}>
      <PercentageBarText data={data} />
    </Box>
  );
};

const PercentageBarLandscape = ({ data }) => {
  React.useEffect(() => {
    const killAnimation = progressBarLandscape();

    return killAnimation;
  }, []);

  return (
    <Box
      className="statistics__porcentage-bar-landscape"
      sx={{
        bg: 'primary',
        gridColumn: [null, null, null, '1/span 20', '1/span 19', '1/span 21'],
        display: ['none', 'none', 'none', 'block', 'block', 'block'],
        ml: fullWidthMinusMargins,
        overflow: 'hidden',
        p: [
          null,
          null,
          null,
          '3.3rem 3.5rem 3.3rem 3.5rem',
          '4rem 5.3rem 4rem 5.3rem',
          '5rem 6.4rem 5rem 6.4rem'
        ],
        textAlign: 'right',
        backgroundImage: `url(${smallNoise})`,
        backgroundSize: [null, null, null, '126%', '135%', 'auto']
      }}>
      <PercentageBarText data={data} />
    </Box>
  );
};

const PercentageBarText = ({ data: { number, label } }) => (
  <>
    <Paragraph
      className="progress-bar__number"
      sx={{
        color: 'secondary',
        fontFamily: 'primary.italic',
        fontSize: ['10.8rem', '14rem', '18rem', '9.6rem', '12rem', '16rem'],
        lineHeight: 1
      }}>
      {number}
    </Paragraph>
    <Paragraph
      className="progress-bar__text"
      sx={{
        color: 'secondary',
        fontFamily: 'primary.bold',
        fontSize: ['1.5rem', '1.7rem', '2rem', '1.5rem', '1.7rem', '1.6rem'],
        lineHeight: 1.35,
        mt: ['1.2rem', 0, '3.2rem', 0, 0, '0.4rem'],
        whiteSpace: [null, null, null, 'nowrap', 'nowrap', 'nowrap'],
        width: ['80%', '56%', '58%', '100%', '100%', '100%']
      }}>
      {label}
    </Paragraph>
  </>
);

const Spacer = () => (
  <GridSpacer
    sx={{
      display: ['block', 'block', 'block', 'block', 'block', 'block'],
      height: ['10rem', '12.5rem', '12.9rem', '13rem', '10.7rem', '14.6rem']
    }}
  />
);
